<template>
    <div class="pageContentBox">
        <div class="pageTopBox">
            <img class="switchRow" src="@/assets/images/aboutUs/top.png" alt="关于我们、沿海智创 · 赋能未来" />
        </div>
        <div class="aboutUsMaxwidth">
            <div class="aboutUsRowLeft">
                <div class="aboutUsRowTitle">沿海智创 · 赋能未来</div>
                <div class="aboutUsRowSubtitle">COMPANY PROFILE</div>
                <div class="aboutUsRowContent">
                    <div class="aboutUsParagraph"> 珠海市沿海信息技术有限公司成立于2014年，2017年通过国家高新技术企业认证并拥有超过40项软件著作权，是美森博多利（硅谷）的珠海软件合作研发中心，擅长AI、XR、WEB3.0(区块链) 等技术领域的应用软件设计与研发。</div>
                    <div class="aboutUsParagraph">公司核心成员来自国内知名软件研发企业的软件研发团队，拥有近二十年的软件合作研发经验以及成熟的研发架构，已经成功的完成了多个大型互联网、移动互联网、区块链和企业资源信息管理（ERP）软件项目。 熟悉的领域包括民航飞行员培训、民航飞机检测与航材管理、护理及养老专业评估与信息化管理，沉浸式AR剧本游戏等领域。曾服务的客户包括沃尔顿链，中国移动、中国电信、中外运、南方航空、全国各大报业集团、港澳大型零售连锁超市，大型眼镜连锁企业、大型医药连锁企业和苹果公司等国内外大型企业。</div>
                </div>
            </div>
            <div class="aboutUsIconBox">
                <img class="aboutUsIconImg" src="@/assets/images/aboutUs/icon1.png" alt="沿海智创 · 赋能未来">
            </div>
        </div>
        <div class="aboutUsRowBox">
            <div class="aboutUsRowMaxwidth">
                <div class="aboutUsLeftBox">
                    <div class="aboutUsRowTitle">沿海智创 · 赋能未来</div>
                    <div class="aboutUsRowSubtitle2">融合科技前沿，推动智慧转型。</div>
                    <div class="aboutUsRowContent">
                        <div class="aboutUsParagraph" style="text-indent: 0;">我们正竭尽全力，致力于成为AI及XR技术应用领域的专业引领者，通过整合AI与虚拟现实技术，为用户创造全新的业务价值与体验。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aboutUsFloatBox">
            <div class="aboutUsIconBox2">
                <img class="aboutUsIcon2" src="@/assets/images/aboutUs/icon2.svg" alt="企业理念">
            </div>
            <div class="aboutUsIdea">
                <div class="ideaList">我们不断创造新的经验和价值，通过对客户需求和技术能力的深刻理解，开辟无限可能。</div>
                <div class="ideaList">我们致力于创新服务和业务，始终将客户和合作伙伴放在首位，以优质的产品赢得信任。</div>
                <div class="ideaList">我们通过创新和合作，共同创造未来的价值。</div>
                <div class="ideaList">我们希望支持那些拥有梦想和激情的人才，并与商业伙伴共同成长。</div>
                <div class="ideaList">作为新技术时代的一员，沿海信息将通过满足客户需求的前沿技术、创新服务和解决方案，持续推动变革。</div>
                <div class="ideaList">我们期待您继续关注和支持我们在互联网领域的新挑战与机遇，共同迎接未来的可能性。</div>
            </div>
        </div>
        <div class="aboutUsWelecomeBox">
            <div class="aboutUsWelecomeMaxWidth">
                <div class="aboutUsRowTitle">欢迎合作</div>
                <div class="aboutUsRowSubtitle">WELECOME TO COOPERATE</div>
                <div class="welecomeContent">
                    <div class="welecomeContentList">与我们携手，共同开创智能化和数字化时代的新篇章。</div>
                    <div class="welecomeContentList">沿海，您的可靠合作伙伴，助力实现更高目标。</div>
                </div>
                <div class="ContactUsBox">
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link1.png" alt="企业电话">
                        <div class="contactTitle">企业电话</div>
                        <div class="contactSubtitle">86-756-8818398</div>
                    </div>
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link2.png" alt="企业电话">
                        <div class="contactTitle">邮箱E-MAIL</div>
                        <div class="contactSubtitle">business@coastalinfo.cn</div>
                    </div>
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link3.png" alt="企业电话">
                        <div class="contactTitle">电话联系</div>
                        <div class="contactSubtitle">86-138-7664-4296</div>
                    </div>
                    <div class="contactList">
                        <img class="contactIcon" src="@/assets/images/aboutUs/link4.png" alt="企业电话">
                        <div class="contactTitle">公司地址</div>
                        <div class="contactSubtitle">珠海市香洲区奥园广场</div>
                        <div class="contactSubtitle">SY6-1501</div>
                    </div>
                </div>
            </div>
        </div>
  
        
        <!-- 底部 -->
        <footerPage />
    </div>
</template>

<script>
    import footerPage from '@/components/footerPage.vue';
    
    export default {
        name: 'productService',
        components: {
            footerPage
        },
        data() {
            return {
                arIndex: 0,
            };
        },
        mounted(){
           
        },
        methods: {
            handleSelect(index) {
                this.arIndex = index
            }
        }
    }
</script>

<style scoped>
    .pageContentBox{
        width: 100%;
        box-sizing: border-box;
    }
    .pageTopBox{
        width: 100%;
        background: url(../../assets/images/aboutUs/top2.jpg) no-repeat;
        background-position: center center;

    }
    .switchRow{
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
        transform: translateX(-50%);
        transition: all .3s;
        animation: switchShow 1s ease 1 forwards; /* 动画名称、持续时间、缓动函数、循环次数 */
    }
    @keyframes switchShow {
        0% {
            transform: translateX(-50%); /* 初始位置 */
        }
        100% {
            transform: translateY(0); /* 回到初始位置 */
        }
    }

    .aboutUsMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        align-items: center;

    }
    .aboutUsRowLeft{
        width: 70%;
        padding: 40px 0;

    }
    .aboutUsRowTitle{
        font-weight: 700;
        color: #333333;
        letter-spacing: 5.2px;
        font-size: 28px;
    }
    .aboutUsRowSubtitle{
        color: #aaaaaa;
        margin: 20px 0 25px;
        font-size: 16px;
    }
    
    .aboutUsRowContent{
        width: 100%;
    }
    .aboutUsParagraph{
        font-size: 16px;
        color: rgba(51, 51, 51, 0.9);
        margin-bottom: 30px;
        text-indent: 2em;
        line-height: 1.8;
    }
    .aboutUsParagraph:last-child{
        margin: 0;
    }
    .aboutUsIconBox{
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .aboutUsIconImg{
        width: 100%;
        height: auto;
        animation: floating 1.5s ease infinite; /* 动画名称、持续时间、缓动函数、循环次数 */
    }
    /* 定义一个名为floating的动画 */
    @keyframes floating {
        0% {
            transform: translateY(0); /* 初始位置 */
        }
        50% {
            transform: translateY(-10px); /* 向上移动4像素 */
        }
        100% {
            transform: translateY(0); /* 回到初始位置 */
        }
    }
    .aboutUsRowBox{
        width: 100%;
        height: 418px;
        box-sizing: border-box;
        background: url(../../assets/images/aboutUs/rowBg1.png) no-repeat center center;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .aboutUsRowMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .aboutUsLeftBox{
        width: 40%;
    }
    .aboutUsRowSubtitle2{
        width: 100%;
        color: #00a3ff;
        font-size: 20px;
        font-weight: 700;
        margin: 20px 0 25px;
    }

    .aboutUsFloatBox{
        width: 1000px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        position: relative;
        top: -50px;
        border-radius: 20px;
        background-color: #0041d5;
        box-sizing: border-box;
        padding: 30px;
    }
    .aboutUsIconBox2{
        width: 180px;
        height: 180px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 16px;
        overflow: hidden;
    }
    .aboutUsIcon2{
        width: 100%;
        height: 100%;
        display: block;
    }
    .aboutUsIdea{
        width: calc(100% - 180px);
        box-sizing: border-box;
        padding-left: 30px;
    }
    .ideaList{
        font-size: 15px;
        color: rgba(255, 255, 255, 0.9);
        margin-bottom: 14px;
    }
    .ideaList:last-child{
        margin: 0;
    }

    .aboutUsWelecomeBox{
        width: 100%;
        background-image: linear-gradient(-253.62984909991644deg, #e8f4ff 0%, #ffffff 100%);
    }
    .aboutUsWelecomeMaxWidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .welecomeContentList{
        color: rgba(51, 51, 51, 0.9);
        font-size: 16px;
        text-align: center;
        line-height: 1.8;
    }
    .ContactUsBox{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 50px 0 0;
    }
    .contactList{
        width: 25%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contactIcon{
        width: 90px;
        height: 90px;
        display: block;
        margin-bottom: 30px;
    }
    .contactTitle{
        width: 100%;
        text-align: center;
        font-weight: 700;
        color: #555555;
        font-size: 20px;
        margin-bottom: 16px;
    }
    .contactSubtitle{
        width: 100%;
        text-align: center;
        font-size: 18px;
        color: #555555;
    }

    @media only screen and (max-width: 768px) {
        .pageTopBox{
            width: 100%;
            height: 150px;
            background-position: center center;
            background-size: auto 100%;
        }
        .switchRow{
            width: auto;
            height: 100%;
            object-fit: fill;
            position: relative;
            left: -20%;
        }
        .vrArTabBox{
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding: 0 20px;
            justify-content: space-between;
        }
        .arArTabList{
            font-size: 14px;
            margin: 0;
            cursor: auto;
        }
        .aboutUsMaxwidth{
            max-width: 100%;
            box-sizing: border-box;
            flex-wrap: wrap;
        }
        .aboutUsRowLeft{
            width: 100%;
            box-sizing: border-box;
            padding: 30px 10px 20px;
        }
        .aboutUsRowTitle,.aboutUsRowSubtitle{
            text-align: center;
        }
        .aboutUsIconBox{
            width: 70%;
            margin: 0 auto;
        }
        .aboutUsRowBox{
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            background-size: 200% 100%;
            background-position: left center;
        }
        .aboutUsRowMaxwidth{
            max-width: 100%;
            box-sizing: border-box;
            padding: 20px 10px;
        }
        .aboutUsLeftBox{
            width: 100%;
        }
        .aboutUsFloatBox{
            width: 90%;
            top: 0;
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 30px;
        }
        .aboutUsRowSubtitle2{
            text-align: center;
        }
        .aboutUsIdea{
            width: 100%;
            padding: 0;
            margin-top: 20px;
        }
        .ideaList{
            font-size: 14px;
        }
        .aboutUsWelecomeMaxWidth{
            max-width: 100%;
            padding: 30px 0;
        }
        .welecomeContentList{
            font-size: 14px;
        }
        .ContactUsBox{
            padding: 30px 10px 0;
            box-sizing: border-box;
            flex-wrap: wrap;
        }
        .contactList{
            width: 50%;
            box-sizing: border-box;
            padding: 10px 0;
        }
        .contactIcon{
            margin-bottom: 20px;
        }
        .contactTitle{
            font-size: 18px;
            margin-bottom: 4px;
        }
        .contactSubtitle{
            font-size: 14px;
        }
        .aboutUsParagraph{
            font-size: 14px;
        }
    }
    
    

    
</style>