// 引入vue-router
import VueRouter from "vue-router";
import Vue from "vue";
Vue.use(VueRouter);

import home from '@/views/Home/home.vue'
import productService from '@/views/productService/index.vue'
import aboutUs from '@/views/aboutUs/index.vue'

// 定义routes路由数组
const routes = [
	{
		path: "/",
		name: "珠海市沿海信息技术有限公司 XR科技|VR研发|AR研发|VR培训|AR场景互动|AI智能应用|虚拟现实解决方案|虚实交互|多人实时互动|虚虚拟仿真教学|人机交互系统|仿真教学|虚拟仿真实验室|虚拟教学|虚拟仿真技术",
		meta: {
			title: '珠海市沿海信息技术有限公司 XR科技|VR研发|AR研发|VR培训|AR场景互动|AI智能应用|虚拟现实解决方案|虚实交互|多人实时互动|虚虚拟仿真教学|人机交互系统|仿真教学|虚拟仿真实验室|虚拟教学|虚拟仿真技术',
			keywords: '沿海信息--XR科技,VR研发,AR研发,VR培训,AR场景互动,AI智能应用,虚拟现实解决方案,多人实时互动,虚实交互,虚虚拟仿真教学,人机交互系统,仿真教学,虚拟仿真实验室,虚拟教学,虚拟仿真技术',
			description: '沿海信息--XR科技,VR研发,AR研发,VR培训,AR场景互动,AI智能应用,虚拟现实解决方案,多人实时互动,虚实交互,虚虚拟仿真教学,人机交互系统,仿真教学,虚拟仿真实验室,虚拟教学,虚拟仿真技术',
		},
		component: home,
	},
	{
		path: "/productService",
		name: "珠海市沿海信息技术有限公司 产品服务",
		meta: {
			title: '珠海市沿海信息技术有限公司 XR科技|VR研发|AR研发|VR培训|AR场景互动|AI智能应用|虚拟现实解决方案|虚实交互|多人实时互动|虚虚拟仿真教学|人机交互系统|仿真教学|虚拟仿真实验室|虚拟教学|虚拟仿真技术',
			keywords: '沿海信息--VR飞行培训,VR智能护理,AR互动游戏,VR冒险游戏,AI智能护理解决方案,护理站,护理服务中心,居家照护',
			description: '沿海信息--VR飞行培训,VR智能护理,AR互动游戏,VR冒险游戏,AI智能护理解决方案,护理站,护理服务中心,居家照护',
		},
		component: productService,
	},
	{
		path: "/aboutUs",
		name: "珠海市沿海信息技术有限公司 关于我们",
		meta: {
			title: '珠海市沿海信息技术有限公司 XR科技|VR研发|AR研发|VR培训|AR场景互动|AI智能应用|虚拟现实解决方案|虚实交互|多人实时互动|虚虚拟仿真教学|人机交互系统|仿真教学|虚拟仿真实验室|虚拟教学|虚拟仿真技术',
			keywords: '沿海信息--AI,XR,Web3.0,互联网开发,移动互联网,区块链,民航飞行,飞机检测,护理养老,虚拟现实',
			description: '沿海信息--AI,XR,Web3.0,互联网开发,移动互联网,区块链,民航飞行,飞机检测,护理养老,虚拟现实',
		},
		component: aboutUs,
	},
	
	
]

// 实例化VueRouter并将routes添加进去
const router = new VueRouter({
	// 配置URL和组价直接的映射关系
	routes,
	// history模式 
	mode: 'history',
})

// 抛出这个这个实例对象方便外部读取以及访问
export default router