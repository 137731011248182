<template>
    <div class="vrarContent">
        <div class="solutionBox">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">AR沉浸式场景互动游戏引擎</div>
                <div class="solutionDesc">通过增强现实技术，将虚拟元素与现实世界无缝融合，为玩家创造身临其境的互动体验。游戏突破了传统的屏幕限制，让玩家在真实环境中参与互动，带来前所未有的沉浸感和乐趣。</div>
                <div class="btnArrBox">
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">身临其境的虚拟世界</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">个性化的动态反馈</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">高度互动的游戏体验</div>
                    </div>
                </div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" src="@/assets/images/productService/arRow1.png" alt="AR沉浸式场景互动游戏引擎" />
                <img class="vrSolutionImg" src="@/assets/images/productService/arRow2.png" alt="AR沉浸式场景互动游戏引擎" />
            </div>
        </div>
        <div class="solutionBox last">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">沉浸式VR冒险</div>
                <div class="solutionSubtitle">带给你不一样的游戏体验！</div>
                <div class="solutionDesc">VR沉浸式场景互动游戏通过高度逼真的虚拟环境和自由的互动体验，提供强烈的沉浸感，带来传统游戏无法比拟的参与度和现实感。VR与游戏相结合的形式不仅增强了娱乐性，还能提升玩家的专注力和学习效果，使虚拟体验更加生动、个性化和富有挑战性。</div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="arSolutionImg" src="@/assets/images/productService/arList1.png" alt="沉浸式VR冒险" />
                <img class="arSolutionImg" src="@/assets/images/productService/arList2.png" alt="沉浸式VR冒险" />
                <img class="arSolutionImg" src="@/assets/images/productService/arList3.png" alt="沉浸式VR冒险" />
                <img class="arSolutionImg" src="@/assets/images/productService/arList4.svg" alt="沉浸式VR冒险" />
                <img class="arSolutionImg" src="@/assets/images/productService/arList5.png" alt="沉浸式VR冒险" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vrPage',
        data() {
            return {

            };
        },
        mounted(){
           
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .vrarMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0 auto;
    }
    .vrarTitleBox{
        width: 100%;
        padding: 30px 50px;
        background-color: #0041d5;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 2;
    }
    .vrarProgramme{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0 0;
        margin: 40px auto;

    }
    
    .vrarRowList{
        width: 450px;
        height: 130px;
        display: flex;
        border-radius: 14px;
        background-color: rgba(218, 244, 253, 0.3);
        box-sizing: border-box;
        padding: 17px;
        position: relative;
    }
    .vrarListIcon{
        width: 60px;
        height: 60px;
        display: block;
        padding-right: 20px;
        position: relative;
        top: -36px;
    }
    .vrarListRight{
        width: calc(100% - 92px);
    }
    .vrarListTitle{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .vrarListDesc{
        width: 100%;
        font-size: 16px;
        color: #333333;
        line-height: 1.6;
    }

    .solutionBox{
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 60px;
        background: #ffffff;
    }
    .solutionBox.last{
        background: #ffffff;
    }
    .solutionMaxWidth{
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .solutionTitle{
        width: 100%;
        text-align: center;
        color: rgba(51, 51, 51, 0.99);
        font-size: 28px;
        font-weight: bold;

    }
    .solutionSubtitle{
        width: 100%;
        text-align: center;
        font-weight: 700;
        color: #000000;
        margin-top: 30px;
    }
    .solutionDesc{
        width: 100%;
        color: #333333;
        text-align: center;
        font-size: 14px;
        line-height: 1.7;
        margin: 30px 0;
    }
    .btnArrBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btnList{
        width: 240px;
        box-sizing: border-box;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0041d5;
        border-radius: 8px;
        margin: 0 5px;
    }
    .btnIconImg{
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
    }
    .btnText{
        font-weight: 700;
        color: #ffffff;
    }
    .vrSolutionMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
    }
    .vrSolutionImg{
        width: calc(50% - 10px);
        height: auto;
        display: block;
        border-radius: 6px;
    }

    .last .solutionMaxWidth{
        padding-bottom: 0;
    }
    .last .solutionDesc{
        margin-bottom: 60px;
    }
    .arSolutionImg{
        width: calc(20% - 5px);
    }

    @media only screen and (max-width: 768px) {
        .vrarMaxwidth{
            max-width: 100%;
        }
        .solutionTitle{
            font-size: 20px;
        }
        .solutionDesc{
            margin: 20px 0 30px;
            box-sizing: border-box;
            padding: 0 10px;
        }
        .btnArrBox{
            flex-wrap: wrap;
        }
        .btnList{
            width: calc(50% - 10px);
            margin: 5px;
            font-size: 14px;
        }
        .btnIconImg{
            margin-right: 5px;
        }
        .vrSolutionMaxwidth{
            padding: 0 10px;
            box-sizing: border-box;
            flex-wrap: wrap;
        }
        .vrSolutionImg{
            width: calc(50% - 5px);
        }
        .solutionBox{
            padding-bottom: 30px;
        }
        .arSolutionImg{
            width: calc(50% - 10px);
            margin: 5px;
        }
       
        
    }

</style>