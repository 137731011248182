<template>
  <div class="footerBox">
    <div class="footerTopBox">
      <div class="footerIconBox">
        <img class="footerImg" src="@/assets/images/footer/footerLogo.png" alt="沿海信息">
      </div>
      <div class="footerContactUs">
        <div class="contactUsTitle">联系我们</div>
        <div class="usCooperate">商务合作：business@coastalinfo.cn</div>
        <div class="usCooperate">合作咨询：86-138-7664-4296</div>
        <div class="usCooperate">投资合作：business@coastalinfo.cn</div>
        <div class="usCooperate">媒体合作：business@coastalinfo.cn</div>
      </div>
      <div class="followBox">
        <div class="contactUsTitle">关注我们</div>
        <div class="usCooperate">公众号：沿海信息</div>
        <img class="usQrCodeImg" src="@/assets/images/footer/footerQrCode.png" alt="沿海信息">
      </div>
    </div>
    <div class="copyright"> <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备14050408号-3 Copyright © 2014-2017珠海沿海信息技术有限公司版权所有</a></div>
  </div>
</template>

<script>

export default {
  name: "footerPage",
  props: {
    msg: String,
  },
  data() {
    return {
      temp: {},
    };
  },
  computed: {

  },
  created() {
   
  },
  mounted() {

  },
  methods: {
   
  },
};
</script>

<style scoped>
  .footerBox{
    width: 100%;
    box-sizing: border-box;
    padding: 50px 0 30px;
    background-color: #f9f9f9;
  }
  .footerTopBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footerIconBox{
    width: 150px;
    height: 150px;
  }
  .footerImg{
    width: 100%;
    height: 100%;
    display: block;
  }
  .footerContactUs{
    padding: 10px 50px;
    border-left: 1px solid #c0c0c0;
    border-right: 1px solid #c0c0c0;
    margin: 0 50px;
  }
  .contactUsTitle{
    font-weight: 700;
    color: rgba(51, 51, 51, 0.9);
    font-size: 18px;
  }
  .usCooperate{
    margin-top: 14px;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.9);
  }
  .usQrCodeImg{
    width: 100px;
    height: 100px;
    display: block;
    margin-top: 10px;
    position: relative;
    left: -6px;
  }
  .copyright{
    width: 100%;
    text-align: center;
    color: rgba(51, 51, 51, 0.9);
    font-size: 13px;
    margin-top: 40px;
  }
  .copyright a{
    color: rgba(51, 51, 51, 0.9);
    text-decoration: none;
  }


  /* 移动端 */
  @media only screen and (max-width: 768px) {
    .footerBox{
      padding: 20px 0;
    }
    .footerTopBox{
      width: 100%;
      flex-direction: column;
    }
    .footerIconBox{
      display: none;
    }
    .footerContactUs{
      width: 100%;
      box-sizing: border-box;
      padding: 10px 30px;
      margin: 0;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .followBox{
      width: 100%;
      box-sizing: border-box;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .usQrCodeImg{
      width: 40%;
      height: auto;
      left: 0;
    }
    .copyright{
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0 10px;
    }


  }

</style>