<template>
    <div class="vrarContent">
        <div class="vrarMaxwidth">
            <div class="vrarTitleBox">
                VR技术正在变革培训行业，通过高度沉浸式的模拟场景，提供更真实和互动的学习体验。在飞行员和护理培训中，VR分别模拟驾驶舱和医院环境，让学员在安全的虚拟空间中提升技能和应对能力，显著提高培训效果和效率。
            </div>
            <div class="vrarProgramme">
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar1.png" alt="沉浸式学习">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">沉浸式学习</div>
                        <div class="vrarListDesc">通过VR技术，学员能够身临其境地进行护理操作练习，增强学习效果。</div>
                    </div>
                </div>
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar2.png" alt="沉浸式学习">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">互动式学习</div>
                        <div class="vrarListDesc">通过虚拟模拟增强实践操作体验。</div>
                    </div>
                </div>
            </div>
            <div class="vrarProgramme">
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar3.png" alt="沉浸式学习">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">安全模拟</div>
                        <div class="vrarListDesc">通过虚拟现实技术提供逼真的环境，安全地训练学员应对危险情况的技能。</div>
                    </div>
                </div>
                <div class="vrarRowList">
                    <img class="vrarListIcon" src="@/assets/images/productService/vrar4.png" alt="沉浸式学习">
                    <div class="vrarListRight">
                        <div class="vrarListTitle">资源丰富</div>
                        <div class="vrarListDesc">多样化学习资源，支持随时随地学习。</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="solutionBox">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">VR沉浸式智能飞行培训解决方案</div>
                <div class="solutionDesc">打造高度逼真的虚拟环境，再现真实飞行的物理环境和天气条件，提供反复练习的机会，使飞行员在安全的虚拟环境中熟悉复杂操作，减少实际训练的风险和成本。</div>
                <div class="btnArrBox">
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">高效</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">灵活</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">无风险</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">个性化定制</div>
                    </div>
                    <div class="btnList">
                        <img class="btnIconImg" src="@/assets/images/productService/answer.png" alt="VR沉浸式智能飞行培训解决方案">
                        <div class="btnText">低成本</div>
                    </div>
                </div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" src="@/assets/images/productService/fangan1.svg" alt="VR沉浸式智能飞行培训解决方案">
                <img class="vrSolutionPlayIcon" src="@/assets/images/productService/play.png" alt="VR沉浸式智能飞行培训解决方案">
            </div>
        </div>
        <div class="solutionBox last">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">VR沉浸式智能护理培训解决方案</div>
                <div class="solutionDesc">利用沉浸式虚拟现实技术，模拟真实护理场景，精准提升护理人员的技能和应急能力，为医疗行业提供更高效、安全的培训解决方案。</div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" src="@/assets/images/productService/fangan2.svg" alt="VR沉浸式智能飞行培训解决方案">
                <img class="vrSolutionPlayIcon" src="@/assets/images/productService/play.png" alt="VR沉浸式智能飞行培训解决方案">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vrPage',
        data() {
            return {

            };
        },
        mounted(){
           
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .vrarMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0 auto;
    }
    .vrarTitleBox{
        width: 100%;
        padding: 30px 50px;
        background-color: #0041d5;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 2;
    }
    .vrarProgramme{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0 0;
        margin: 40px auto;

    }
    
    .vrarRowList{
        width: 450px;
        height: 130px;
        display: flex;
        border-radius: 14px;
        background-color: rgba(218, 244, 253, 0.3);
        box-sizing: border-box;
        padding: 17px;
        position: relative;
    }
    .vrarListIcon{
        width: 60px;
        height: 60px;
        display: block;
        padding-right: 20px;
        position: relative;
        top: -36px;
    }
    .vrarListRight{
        width: calc(100% - 92px);
    }
    .vrarListTitle{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .vrarListDesc{
        width: 100%;
        font-size: 16px;
        color: #333333;
        line-height: 1.6;
    }

    .solutionBox{
        width: 100%;
        box-sizing: border-box;
        background-image: linear-gradient(-241.44183657682603deg, #e8f4ff 0%, #ffffff 100%);
        padding-bottom: 60px;
    }
    .solutionBox.last{
        background: #ffffff;
    }
    .solutionMaxWidth{
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .solutionTitle{
        width: 100%;
        text-align: center;
        color: rgba(51, 51, 51, 0.99);
        font-size: 28px;
        font-weight: bold;

    }
    .solutionDesc{
        width: 100%;
        color: #333333;
        text-align: center;
        font-size: 14px;
        line-height: 1.7;
        margin: 30px 0;
    }
    .btnArrBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btnList{
        width: 170px;
        box-sizing: border-box;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0041d5;
        border-radius: 8px;
        margin: 0 5px;
    }
    .btnIconImg{
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
    }
    .btnText{
        font-weight: 700;
        color: #ffffff;
    }
    .vrSolutionMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }
    .vrSolutionImg{
        width: 100%;
        height: auto;
        display: block;
        transition: all .3s;
    }
    .vrSolutionImg:hover{
        transform: scale(1.03);
    }
    .vrSolutionPlayIcon{
        width: 80px;
        height: 80px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .last .solutionMaxWidth{
        padding-bottom: 0;
    }


    @media only screen and (max-width: 768px) {
        .vrarTitleBox{
            font-size: 14px;
            padding: 30px;
        }
        .vrarMaxwidth{
            padding: 20px 10px;
            max-width: 100%;
        }
        .vrarProgramme{
            margin: 0 auto;
            padding: 0;
            width: 100%;
        }
        .vrarRowList{
            width: 90%;
            height: 130px;
            display: flex;
            border-radius: 14px;
            background-color: rgba(218, 244, 253, 0.3);
            box-sizing: border-box;
            padding: 17px;
            position: relative;
            margin: 0 auto;
            margin-top: 40px;
        }
        .vrarListIcon{
            width: 60px;
            height: 60px;
            display: block;
            padding-right: 20px;
            position: relative;
            top: -36px;
        }
        .vrarListDesc{
            font-size: 14px;
        }
        .solutionMaxWidth{
            width: 100%;
            box-sizing: border-box;
            padding: 30px 10px;
        }
        .solutionTitle{
            font-size: 20px;
        }
        .solutionDesc{
            margin: 20px 0 30px;
            box-sizing: border-box;
            padding: 0 10px;
        }
        .btnArrBox{
            flex-wrap: wrap;
        }
        .btnList{
            width: 30%;
            font-size: 14px;
            margin: 5px;
        }
        .btnIconImg{
            margin-right: 5px;
        }
        .vrSolutionMaxwidth{
            padding: 10px;
            box-sizing: border-box;
        }
        .solutionBox{
            padding-bottom: 20px;
        }
    }

</style>