<template>
  <div class="headerBox">
    <img class="header_logo_img" src="@/assets/images/logo.png" alt="Ezpilot" />
    <div class="header_TabbarBox">
      <div class="tabRow" :class="activeIndex == 0 ? 'tabActive':''" @click="handleSelect(0)">首页</div>
      <div class="tabRow" :class="activeIndex == 1 ? 'tabActive':''" @click="handleSelect(1)">产品服务</div>
      <div class="tabRow" :class="activeIndex == 2 ? 'tabActive':''" @click="handleSelect(2)">关于我们</div>
      <div class="lineBox" :style="'left:'+ (activeIndex * 140 + 35) +'px'"></div>
    </div>
    <div class="header_moble_Tab">
      <el-popover placement="bottom" trigger="click">
        <div class="mobileTabBox">
          <div class="mobileTabRow" :class="activeIndex == 0 ? 'tabActive':''" @click="handleSelect(0)">首页</div>
          <div class="mobileTabRow" :class="activeIndex == 1 ? 'tabActive':''" @click="handleSelect(1)">产品服务</div>
          <div class="mobileTabRow" :class="activeIndex == 2 ? 'tabActive':''" @click="handleSelect(2)">关于我们</div>
        </div>
        <el-button plain icon="el-icon-s-unfold" slot="reference"></el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>

export default {
  name: "headerPage",
  props: {
    msg: String,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  mounted () {
    // 获取顶部导航下标
    this.activeIndex = localStorage.getItem('activeIndex');
  },
  methods: {
    handleSelect(index) {
      if(this.activeIndex == index) return
      const routesPath = this.$router.options.routes
      this.activeIndex = index
      // 存储顶部导航下标
      localStorage.setItem('activeIndex', index);
      this.$router.push({ path: routesPath[index].path });
      // 切换页面后滚动条置于顶部
      const element = document.getElementById("pageMain");
      element.scrollTop = 0;
    }
  },
};
</script>

<style scoped>
  .headerBox{
    width: 100%;
    max-width: 1200px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
  }
  .header_logo_img{
    width: 154px;
    height: 52px;
  }
  .header_TabbarBox{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .header_moble_Tab{
    display: flex;
  }
  .tabRow{
    width: 140px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    font-size: 16px;
    color: rgba(51, 51, 51, 0.7);
    cursor: pointer;
    transition: all .3s;
  }
  .tabRow.tabActive{
    color: #000000;
    font-weight: 400;
  }
  .lineBox{
    width: 70px;
    height: 3px;
    background: #409EFF;
    transition: all .3s;
    position: absolute;
    bottom: 0;
  }
  @media screen and (min-width: 768px) {
    .header_TabbarBox{
      display: flex;
    }
    .header_moble_Tab{
      display: none;
    }
  }
  /* 移动端 */
  @media only screen and (max-width: 768px) {
    .headerBox{
      height: auto;
      padding: 10px;
      box-sizing: border-box;
    }
    .header_TabbarBox{
      display: none;
    }
    .header_moble_Tab{
      display: inline;
    }
    .header_moble_Tab .el-button{
      padding: 8px 10px;
      font-size: 20px;
    }
    .mobileTabBox{
      width: 100%;

    }
    .mobileTabRow{
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: rgba(51, 51, 51, 0.7);
      padding: 6px 0;
    }
    .mobileTabRow.tabActive{
      color: #409EFF;
    }
  }
  
</style>
