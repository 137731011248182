<template>
    <div class="vrarContent">
        <div class="vrarMaxwidth">
            <div class="vrarTitleBox">
                AI技术正在赋能护理行业，通过智能平台实现精准数据处理和辅助决策，优化护理服务质量、提升资源利用效率，并推动护理服务的数字化升级。
            </div>
        </div>
        <div class="solutionBox">
            <div class="solutionMaxWidth">
                <div class="solutionTitle">AI驱动的智能护理解决方案</div>
                <div class="solutionDesc">AI+护理平台通过构建护理行业知识库和语言模型，利用信息化技术为护理机构赋能，提供个性化、专业化、实时化的康护服务。同时通过AI智能交互（Nurse-Chat）和多维度数据分析，生成健康信息画像，支持长期专业护理方案的制定和实施。</div>
            </div>
            <div class="vrSolutionMaxwidth">
                <img class="vrSolutionImg" src="@/assets/images/productService/aiList1.png" alt="VR沉浸式智能飞行培训解决方案">
            </div>
        </div>
        <div class="aiMaxWidth">
            <div class="aiInfoTitle">智能护理优势与提升</div>
            <div class="aiInfoListBox">
                <div class="aiInfoRow">
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo1.png" alt="提升服务满意度">
                        <div class="aiGridTitle">提升服务满意度</div>
                        <div class="aiGridContent">通过个性化护理和高效服务，提高患者和服务对象的满意度。</div>
                    </div>
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo2.png" alt="快速落地应用迭代">
                        <div class="aiGridTitle">快速落地应用迭代</div>
                        <div class="aiGridContent">神经节点知识库的护理评估和方案独立运行，支持快速推出特定护理服务并持续优化。</div>
                    </div>
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo3.png" alt="护理专业能力提升">
                        <div class="aiGridTitle">护理专业能力提升</div>
                        <div class="aiGridContent">提供精准的辅助决策和培训，显著提升护理人员的专业技能。</div>
                    </div>
                </div>
                <div class="aiInfoRow towRow">
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo4.png" alt="人力需求优化">
                        <div class="aiGridTitle">人力需求优化</div>
                        <div class="aiGridContent">通过智能调度和自动化流程，减少对人力的依赖，提高资源配置效率。</div>
                    </div>
                    <div class="aiInfoGrid">
                        <img class="aiGridImg" src="@/assets/images/productService/aiInfo5.png" alt="业务拓展能力提升">
                        <div class="aiGridTitle">业务拓展能力提升</div>
                        <div class="aiGridContent">通过智能化和数据驱动的分析，帮助护理机构更快地拓展业务范围。</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        name: 'vrPage',
        data() {
            return {

            };
        },
        mounted(){
           
        },
        methods: {
            
        }
    }
</script>

<style scoped>
    .vrarContent{
        padding-bottom: 40px;
    }
    .vrarMaxwidth{
        width: 100%;
        max-width: 1100px;
        box-sizing: border-box;
        padding: 40px 0;
        margin: 0 auto;
    }
    .vrarTitleBox{
        width: 100%;
        padding: 30px 35px;
        background-color: #0041d5;
        border-radius: 6px;
        box-sizing: border-box;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 2;
    }
    .vrarProgramme{
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0 0;
        margin: 40px auto;

    }
    
    .vrarRowList{
        width: 450px;
        height: 130px;
        display: flex;
        border-radius: 14px;
        background-color: rgba(218, 244, 253, 0.3);
        box-sizing: border-box;
        padding: 17px;
        position: relative;
    }
    .vrarListIcon{
        width: 60px;
        height: 60px;
        display: block;
        padding-right: 20px;
        position: relative;
        top: -36px;
    }
    .vrarListRight{
        width: calc(100% - 92px);
    }
    .vrarListTitle{
        width: 100%;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
    }
    .vrarListDesc{
        width: 100%;
        font-size: 16px;
        color: #333333;
        line-height: 1.6;
    }

    .solutionBox{
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 110px;
        background: #ffffff;
    }
    .solutionBox.last{
        background: #ffffff;
    }
    .solutionMaxWidth{
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .solutionTitle{
        width: 100%;
        text-align: center;
        color: rgba(51, 51, 51, 0.99);
        font-size: 28px;
        font-weight: bold;

    }
    .solutionDesc{
        width: 100%;
        color: #333333;
        text-align: center;
        font-size: 14px;
        line-height: 1.7;
        margin: 30px 0;
    }
    .btnArrBox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btnList{
        width: 170px;
        box-sizing: border-box;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0041d5;
        border-radius: 8px;
        margin: 0 5px;
    }
    .btnIconImg{
        width: 20px;
        height: 20px;
        display: block;
        margin-right: 10px;
    }
    .btnText{
        font-weight: 700;
        color: #ffffff;
    }
    .vrSolutionMaxwidth{
        width: 100%;
        max-width: 1100px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
    }
    .vrSolutionImg{
        width: 100%;
        height: auto;
        display: block;
    }
    
    .vrSolutionPlayIcon{
        width: 80px;
        height: 80px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .last .solutionMaxWidth{
        padding-bottom: 0;
    }

    .aiMaxWidth{
        width: 1100px;
        margin: 0 auto;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(194, 194, 194, 0.76);
        background-color: rgba(255, 255, 255, 0.99);
        overflow: hidden;
    }
    .aiInfoTitle{
        width: 100%;
        height: 60px;
        background: #e6e9f2;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(51, 51, 51, 0.9);
        font-size: 22px;
        font-weight: bold;
    }
    .aiInfoListBox{
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }
    .aiInfoRow{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        border-bottom: 1px solid #e6e9f2;
    }
    .aiInfoGrid{
        width: 33.33%;
        border-right: 1px solid #e6e9f2;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }
    .aiInfoGrid:last-child{
        border: none;
    }
    .aiGridImg{
        width: 80px;
        height: 80px;
        display: block;
    }
    .aiGridTitle{
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        color: #333333;
        text-align: center;
        padding: 20px 0;
    }
    .aiGridContent{
        width: 100%;
        color: #333333;
        font-size: 14px;
        text-align: center;
    }
    .towRow{
        border: none;
    }
    .towRow .aiInfoGrid{
        width: 50%;
    }

    @media only screen and (max-width: 768px) {
        .vrarMaxwidth{
            max-width: 100%;
            padding: 20px 10px;
        }
        .vrarTitleBox{
            font-size: 14px;
            padding: 30px;
        }
        .solutionMaxWidth{
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            padding: 10px;
        }
        .solutionTitle{
            font-size: 20px;
        }
        .solutionDesc{
            margin: 15px 0;
        }
        .vrSolutionMaxwidth{
            width: 100%;
            max-width: 100%;
            box-sizing: border-box;
            padding: 0 10px;
        }
        .solutionBox{
            padding-bottom: 30px;
        }
        .aiMaxWidth{
            width: 90%;
            box-sizing: border-box;
        }
        .aiInfoRow{
            flex-wrap: wrap;
            border: none;
        }
        .aiInfoGrid{
            width: 100%;
            padding: 20px 10px;
            border: none;
        }
        .towRow .aiInfoGrid{
            width: 100%;
        }

    }

</style>