import { render, staticRenderFns } from "./arPage.vue?vue&type=template&id=1bbe32aa&scoped=true"
import script from "./arPage.vue?vue&type=script&lang=js"
export * from "./arPage.vue?vue&type=script&lang=js"
import style0 from "./arPage.vue?vue&type=style&index=0&id=1bbe32aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bbe32aa",
  null
  
)

export default component.exports