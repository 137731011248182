<template>
  <el-container id="app">
    <el-header class="elHeaderBox">
      <headerPage />
    </el-header>
    <el-main id="pageMain" class="pageMain">
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
import headerPage from "./components/headerPage.vue";
export default {
  name: 'App',
  components: {
    headerPage
  },
  mounted(){
    
  },
}
</script>

<style>
  body{
    margin: 0;
    padding: 0;
  }
  .elHeaderBox{
    padding: 0!important;
    height: auto!important;
    box-shadow: 0px 0px 5px 0px rgba(89, 106, 115, 0.3);
  }
  
  /* PC */
  @media screen and (min-width: 768px) {
    .pageMain {
      width: 100%;
      height: calc(100vh - 72px);
      padding: 0!important;
      overflow: hidden!important;
      overflow-y: scroll!important;
    }
    /* 滚动条样式 */
    ::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      background-color: #ebeef5;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: #ccc;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-track{
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: rgba(255, 255, 255, 1);
    }


  }
  /* 移动端 */
  @media only screen and (max-width: 768px) {
    .pageMain {
      width: 100%;
      height: calc(100vh - 72px);
      padding: 0!important;
      overflow: hidden;
      overflow-y: scroll;
    }
    
  }
</style>
